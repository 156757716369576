import React, { useEffect, useState } from "react"
import { changeBreadcrumbs, changeTitle } from "../utils/page"
import IlsBeranda from "../assets/img/illustrations/man-with-laptop-light.png"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { request } from "../utils/request"
import { refreshDashboard } from "../utils/config"
import { dateTime, strtotime } from "../utils/formatter"
import HeadTable from "../components/Table/HeadTable"

const Beranda = (props) => {
    const { auth } = props

    const [data, setData] = useState({
        total: {
            daftarUlangHarian: 0,
            soalUjian: 0,
            kategoriSoal: 0,
            sesiUjian: 0,
            hasilSeleksi: 0,
            daftarUlang: 0,
            pesertaTanpaSesi: 0,
            ujianPeserta: 0
        },
        list: {
            ujianPerSesi: []
        }
    })

    useEffect(() => {
        const titlePage = "Beranda"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        actionFetchDashboard()

        if (refreshDashboard === true) {
            let intervalId = setInterval(() => actionFetchDashboard(), 30000)

            return () => clearInterval(intervalId)
        }
    }, [])

    const actionFetchDashboard = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${auth?.accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_DASHBOARD + '/main', {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setData(res?.result)
            }
        } catch (error) { }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-12 mb-2 order-0">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card mb-4">
                                <div className="d-flex align-items-end row">
                                    <div className="col-sm-7">
                                        <div className="card-body">
                                            <h5 className="card-title text-primary">Selamat Datang{" " + auth?.user?.name}! 🎉</h5>
                                            {
                                                data?.total?.daftarUlangHarian > 0 ?
                                                    <p className="mb-4">{data?.total?.daftarUlangHarian} pelajar mengirim tugas hari ini.</p>
                                                    :
                                                    <p className="mb-4">Belum ada pelajar mengirim tugas hari ini.</p>
                                            }
                                            <Link to={"/penilaian-tugas-pelajar?search=" + dateTime(strtotime(new Date()), "YYYY-MM-DD")} className="btn btn-sm btn-label-primary">Lihat Tugas Pelajar</Link>
                                        </div>
                                    </div>
                                    <div className="col-sm-5 text-center text-sm-left">
                                        <div className="card-body pb-0 px-0 px-md-4">
                                            <img src={IlsBeranda} height="140" alt="View Badge User" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-md-6 col-6 mb-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="card-title d-flex align-items-start justify-content-between">
                                                <div className="flex-shrink-0">
                                                    <i className="bx bx-task" style={{ fontSize: "3.2rem", color: "#20827b" }}></i>
                                                </div>
                                            </div>
                                            <span className="d-block">Tugas Pelajar</span>
                                            <h4 className="card-title mb-1">{data?.total?.jumlahTugasPelajar || 0}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-6 mb-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="card-title d-flex align-items-start justify-content-between">
                                                <div className="flex-shrink-0">
                                                    <i className="bx bx-spreadsheet" style={{ fontSize: "3.2rem", color: "#ff6600" }}></i>
                                                </div>
                                            </div>
                                            <span className="d-block">Capaian Pelajar</span>
                                            <h4 className="card-title mb-1">{data?.total?.jumlahCapaianPelajar || 0}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-category-alt" style={{ fontSize: "3.2rem", color: "#ff6600" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Kursus</span>
                                    <h4 className="card-title mb-1">{data?.total?.jumlahKursus || 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-file" style={{ fontSize: "3.2rem", color: "#20827b" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Modul</span>
                                    <h4 className="card-title mb-1">{data?.total?.jumlahModul || 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 mb-4 d-block d-md-none">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-copy-alt" style={{ fontSize: "3.2rem", color: "#20827b" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Pertemuan</span>
                                    <h4 className="card-title mb-1">{data?.total?.jumlahPertemuan || 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 mb-4 d-block d-md-none">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-book" style={{ fontSize: "3.2rem", color: "#ff6600" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Jobsheet</span>
                                    <h4 className="card-title mb-1">{data?.total?.jumlahJobsheet || 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 mb-4 d-none d-md-block">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-copy-alt" style={{ fontSize: "3.2rem", color: "#ff6600" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Pertemuan</span>
                                    <h4 className="card-title mb-1">{data?.total?.jumlahPertemuan || 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 mb-4 d-none d-md-block">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-book" style={{ fontSize: "3.2rem", color: "#20827b" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Jobsheet</span>
                                    <h4 className="card-title mb-1">{data?.total?.jumlahJobsheet || 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-image-alt" style={{ fontSize: "3.2rem", color: "#ff6600" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Gambar</span>
                                    <h4 className="card-title mb-1">{data?.total?.jumlahGambar || 0}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="flex-shrink-0">
                                            <i className="bx bx-video" style={{ fontSize: "3.2rem", color: "#20827b" }}></i>
                                        </div>
                                    </div>
                                    <span className="d-block">Video</span>
                                    <h4 className="card-title mb-1">{data?.total?.jumlahVideo || 0}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="card pt-0">
                <div className="card-body">
                    <small className="text-muted text-uppercase">Waktu Pertemuan Pelajar ( 5 Terbaru )</small>
                    <div className="table-responsive grid-view mt-2">
                        <table className="table table-bordered">
                            <HeadTable
                                colorized={false}
                                columns={[
                                    { type: "label", label: "Nama Pelajar" },
                                    { type: "label", label: "Pertemuan" },
                                    { type: "label", label: "Modul" },
                                    { type: "label", label: "Kursus" },
                                    { type: "label", label: "Waktu Mulai" },
                                    { type: "label", label: "Waktu Selesai" }
                                ]}
                            />
                            <tbody className="table-border-bottom-0">
                                {
                                    data?.list?.waktuPertemuanPelajar?.length > 0 ?
                                        data?.list?.waktuPertemuanPelajar?.map((val, key) => {
                                            return <React.Fragment key={key}>
                                                <tr className={(val?.total > val?.batas_peserta ? "table-warning" : "")}>
                                                    <td>{val?.pelajar?.user?.name}</td>
                                                    <td>{val?.pertemuan?.nama}</td>
                                                    <td>{val?.modul?.nama}</td>
                                                    <td>{val?.kursus?.nama}</td>
                                                    <td>{val?.waktu_mulai}</td>
                                                    <td>{val?.waktu_selesai}</td>
                                                </tr>
                                            </React.Fragment>
                                        })
                                        : <tr>
                                            <td colSpan={6}>Data tidak ditemukan</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
        </div>
    </React.Fragment >
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

export default withRouter(connect(mapStateToProps, {})(Beranda))